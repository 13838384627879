/*
Vue-Control
.carousel {
    max-width: 738px !important;
    &__item--active {
        max-width: 690px !important;
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    &__list {
        min-height: auto;
    }
} */


/* Boostrap */

.carousel {
    width: 100%;
}

.carousel-item {
    padding-left: 5%;
    padding-right: 5%;
    text-decoration: none;
    min-width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
    text-decoration: none;
    width: 5%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='0062a1' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.rezension-text {
    width: 100%;
    color: black;
    margin-top: 5px;
}

.rezension-author {
    font-weight: bold;
    color: black;
    font-size: large;
}

.rating-info{
    padding-left: 15px;
    font-size: 18px;
    line-height: 32px;
}
