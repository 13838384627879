//** FONTS **//

/* ubuntu-regular - latin */

@font-face {
    font-family: 'Ubuntu', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    src: local(''), url('/fonts/ubuntu-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/ubuntu-v15-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-700 - latin */

@font-face {
    font-family: 'Ubuntu', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    src: local(''), url('/fonts/ubuntu-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/ubuntu-v15-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}
