

p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 15px;
}

h2 {
    color: #fff;
    font-size: 23px;
    letter-spacing: 0.5px;
    line-height: 30px;
    margin: 20px 0;
}

h1 {
    color: $primary;
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 30px;
}

h4,
h5 {
    color: $primary;
    font-weight: 400;
    letter-spacing: 0;
}

h5 {
    margin-bottom: 5px;
}

.dunkelblau {
    color: $primary;
}

.blau {
    color: $primary-light;
}

.rot {
    color: #a50202;
}

.bg-color-hellblau {
    background-color: $primary-light;
}

.inhalt a,
.inhalt a:focus {
    color: $primary;
    text-decoration: none;
    border-bottom: 1px dotted $primary;
}

.inhalt a:hover {
    color: #a50202;
    text-decoration: none;
    border-bottom: 1px solid #a50202;
}

.mb-3,
.my-3 {
    margin-bottom: 2rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 3.5rem !important;
}

.hintergrund {
    width: 100%;
    height: 100%;
    max-height: 500px;
}

.feature-box.feature-box-style-2 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-2 .feature-box-icon .icons {
    color: $primary;
}

.foto-start {
    width: 100%;
    max-width: 155px;
    border: 1px solid #a6a6a6;
    -webkit-box-shadow: 0px 0px 5px 4px rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 0px 0px 5px 4px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 0px 5px 4px rgba(50, 50, 50, 0.2);
}

html .bg-color-primary,
html .bg-primary {
    background-color: $primary;
}

.logogroesse {
    width: 244px;
    height: 58px;
}

#header .header-nav-main nav>ul>li>a {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

html .list-primary.list-icons li>[class*="fa-"]:first-child,
html .list-primary.list-icons li a:first-child>[class*="fa-"]:first-child,
html .list-primary.list-icons li>.icons:first-child,
html .list-primary.list-icons li a:first-child>.icons:first-child {
    color: $primary;
    border-color: $primary;
}

.list.list-icons.list-icons-sm li>[class*="fa-"]:first-child,
.list.list-icons.list-icons-sm li>.icons:first-child,
.list.list-icons.list-icons-sm li a:first-child>[class*="fa-"]:first-child,
.list.list-icons.list-icons-sm li a:first-child>.icons:first-child {
    font-size: 15px;
    top: 4px;
}

.btn-primary-blau {
    background-color: $primary;
    border-color: $primary $primary #b3b3b3;
    color: #FFF;
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
    background: $primary;
    color: #FFF;
}

.thumb-info .thumb-info-type {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: none;
    line-height: 2.3;
}

.thumb-info .thumb-info-inner {
    text-transform: uppercase;
    letter-spacing: 0.25px;
}

.text-1 {
    font-size: 13px !important;
    color: #fff;
    letter-spacing: 0px;
    font-weight: 400;
}

.text-1 a {
    color: #fff;
    border-bottom: 1px dotted #fff;
}

.text-1 a:hover {
    border-bottom: 1px dotted #fff;
    color: #ddd;
    border-bottom: 0;
}

.text-4,
.text-4 a {
    font-size: 14px !important;
    color: #f1f1f1 !important;
}

.text-4 a:hover {
    color: #ccc !important;
}

html .scroll-to-top {
    background: $primary-light;
}

.text-xs-right {
    text-align: right !important;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 10px 10px;
}

@media (min-width: 992px) {
    #header .header-nav-main nav>ul>li.dropdown .dropdown-menu li a {
        border-bottom: 1px solid #f7f7f7;
        color: #777;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 18px 6px 18px;
        position: relative;
        text-transform: none;
        letter-spacing: 0.5px;
    }
    #header .header-nav-main nav>ul>li.dropdown .dropdown-menu {
        top: -10000px;
        display: block;
        opacity: 0;
        left: auto;
        border-radius: 0 4px 4px;
        border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
        border-top: 3px solid #CCC;
        -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07);
        box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07);
        margin: 0;
        margin-top: 0px;
        margin-left: 0px;
        min-width: 250px;
        padding: 5px 0;
        text-align: left;
    }
    html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light>a.active {
        background: #FFF !important;
        color: #333 !important;
    }
    html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light:hover>a {
        background: #FFF;
        color: #333;
    }
}

@media (max-width: 1139px) {
    .hintergrund {
        width: 100%;
        height: 100%;
        max-height: 420px;
    }
}

@media (max-width: 991px) {
    .hintergrund {
        width: 100%;
        height: 100%;
        max-height: 380px;
    }
    #header {
        height: 114px !important;
    }
    #header .header-nav-main nav>ul>li>a {
        text-transform: uppercase;
        font-weight: 300;
        color: #353535;
    }
    #header .header-nav-bar,
    #header .header-nav-bar .header-nav {
        height: 8px !important;
    }
    #header .header-nav-bar .header-nav {
        min-height: 8px !important;
    }
    .kopf {
        margin-top: -53px !important;
    }
    .zentriert {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .smartphone1 {
        display: none !important;
    }
    .text-xs-right {
        text-align: left !important;
    }
}

@media (max-width: 767px) {
    .hintergrund {
        width: 100%;
        height: 100%;
        max-height: 300px;
    }
}

@media (max-width: 550px) {
    .logogroesse {
        width: 200px;
        height: 48px;
    }
    .hintergrund {
        width: 100%;
        height: 100%;
        max-height: 220px;
    }
}
