@charset "UTF-8";

/*
Name: 			theme-elements.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	7.5.0
*/


/* Feature Box */

.feature-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.feature-box .feature-box-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 100%;
    color: #FFF;
    font-size: 16px;
    font-size: 1rem;
    position: relative;
    top: 7px;
    text-align: center;
    padding: 0.8em;
    height: 1px;
    width: 2.7em;
    height: 2.7em;
}

.feature-box .feature-box-info {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding-left: 15px;
}

.feature-box.align-items-center .feature-box-icon {
    top: 0;
}


/* Style 2 */

.feature-box.feature-box-style-2 h4 {
    color: #212529;
}

.feature-box.feature-box-style-2 .feature-box-icon {
    background: transparent;
    top: -3px;
}

.feature-box.feature-box-style-2 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-2 .feature-box-icon .icons {
    font-size: 1.7em;
}

.feature-box.feature-box-style-2.align-items-center .feature-box-icon {
    top: 0;
}


/*
* IE
*/

html.ie .feature-box .feature-box-icon img {
    min-width: 100%;
}

html.ie .feature-box .feature-box-info {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
}


/* Forms */

form label {
    font-weight: normal;
}

textarea {
    resize: vertical;
}

select {
    border: 1px solid #E5E7E9;
    border-radius: 6px;
    outline: none;
}

.label {
    font-weight: normal;
}

label.required:after {
    content: "*";
    font-size: 0.8em;
    margin: 0.3em;
    position: relative;
    top: -2px;
}

.form-group:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.form-control {
    border-color: rgba(0, 0, 0, 0.09);
}

.form-control:not(.form-control-lg) {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.3;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 13.6px;
    font-size: 0.85rem;
    line-height: 1.85;
}

.form-control.form-control-focused {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-color: #CCC;
}

.form-control:focus {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-color: #CCC;
}

.form-control.error {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control.error:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.page-link {
    border-color: rgba(0, 0, 0, 0.06);
}

.input-group .form-control {
    height: auto;
}

.input-group-rounded .form-control {
    border-radius: 2rem 0rem 0rem 2rem;
    border: 0;
    padding-left: 16px;
    padding-left: 1rem;
}

.input-group-rounded .input-group-append .btn {
    border-color: rgba(0, 0, 0, 0.09);
    border-radius: 0rem 2rem 2rem 0rem;
    font-size: 11.2px;
    font-size: 0.7rem;
    padding: 13.92px;
    padding: 0.87rem;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    z-index: 3;
}


/* Form - iOS Override */

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
    -webkit-appearance: none;
}

.form-control::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="color"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #bdbdbd;
}

.form-control::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="color"]::-moz-placeholder,
textarea::-moz-placeholder {
    color: #bdbdbd;
}

.form-control:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="color"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #bdbdbd;
}


/* Form - Bootstrap Override */

.btn-lg,
.btn-group-lg>.btn {
    line-height: 1.334;
}

.input-group-btn .btn {
    padding-top: 8px;
    padding-top: 0.5rem;
    padding-bottom: 8px;
    padding-bottom: 0.5rem;
}

select.form-control-sm,
select.form-control-lg {
    line-height: 1;
    height: auto !important;
}

.bootstrap-timepicker-widget input {
    border: 0;
}

.bootstrap-timepicker-widget table td input {
    width: 40px;
}

.fileupload .btn {
    line-height: 20px;
    margin-left: -5px;
}


/* Forms Validations */

label.valid {
    display: inline-block;
    text-indent: -9999px;
}

label.error {
    color: #c10000;
    font-size: 0.9em;
    line-height: 18px;
    padding: 5px 0 0;
}

label.error#captcha-error {
    margin-top: 15px;
}

.form-errors-light .form-control.error {
    border-color: #FFF;
}

.form-errors-light label.error {
    color: #FFF;
}


/* Simple Search */

.simple-search .form-control {
    border-radius: 1.3rem 0 0 1.3rem;
    border-color: #EEE;
    border-right: 0;
}

.simple-search .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.simple-search .input-group-append {
    border-radius: 0 1.3rem 1.3rem 0;
    border: 1px solid #EEE;
    border-left: 0;
}

.simple-search .input-group-append .btn {
    background: transparent !important;
    border: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}


/* Captcha */

.captcha {
    clear: both;
    overflow: hidden;
    background: #F2F2F2;
    text-align: center;
    margin-bottom: 20px;
    max-width: 160px;
    height: 78px;
    position: relative;
}

.captcha input {
    border: 0;
}

.captcha-input {
    position: relative;
    top: 15px;
    font-size: 14px;
}

.captcha-image {
    float: left;
    position: relative;
    top: -5px;
    left: -13px;
}

.captcha-refresh {
    position: absolute;
    z-index: 1;
    bottom: 2px;
    right: 2px;
    border-radius: 3px;
    padding: 2px 5px;
}

.captcha-refresh a,
.captcha-refresh a:hover,
.captcha-refresh a:focus,
.captcha-refresh a:active {
    color: #333;
}


/* Form Styles */

.form-style-2 .form-group {
    margin-bottom: 11.2px;
    margin-bottom: 0.7rem;
}

.form-style-2 .form-control {
    padding: 11.2px 16px;
    padding: 0.7rem 1rem;
    border: 0;
}

.form-style-2 .form-control::-webkit-input-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-2 .form-control::-moz-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-2 .form-control:-ms-input-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-2 .form-control:-moz-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-2 .form-control.error {
    border-width: 2px;
}

.form-style-3 .form-group {
    margin-bottom: 17.6px;
    margin-bottom: 1.1rem;
}

.form-style-3 .form-control {
    padding: 11.2px 16px;
    padding: 0.7rem 1rem;
    background: #F4F4F4;
    border: none;
}

.form-style-3 .form-control::-webkit-input-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-3 .form-control::-moz-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-3 .form-control:-ms-input-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-3 .form-control:-moz-placeholder {
    color: #202528;
    opacity: 0.5;
}

.form-style-3 .form-control.error {
    border: 2px solid #e21a1a;
}

.form-style-4 .form-control {
    background: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom-color: rgba(0, 0, 0, 0.4);
    color: #FFF;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}



/* Icons */

.featured-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #CCC;
    border-radius: 100%;
    color: #FFF;
    height: 2.5em;
    line-height: 2.5em;
    margin-right: 10px;
    position: relative;
    text-align: center;
    top: 5px;
    width: 2.5em;
}

.featured-icon i {
    color: #FFF;
    font-size: 0.9em;
}

.featured-icon.featured-icon-style-2 {
    width: 5em;
    height: 5em;
    line-height: 5em;
}

.featured-icon.featured-icon-hover-effect-1 {
    -webkit-transition: ease transform 300ms;
    transition: ease transform 300ms;
}

.featured-icon.featured-icon-hover-effect-1:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}



/* Loading Overlay */

.loading-overlay-showing {
    overflow: hidden;
}

.loading-overlay-showing>.loading-overlay {
    opacity: 1;
    visibility: visible;
}

.loading-overlay {
    -webkit-transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
    transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    visibility: hidden;
    background: #FFF;
}

body>.loading-overlay {
    position: fixed;
    z-index: 999999;
}

/* Clearfix */

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}


/* Min Height */

.min-height-screen {
    min-height: 100vh;
}


/* Hide Text */

.hide-text {
    display: block;
    text-indent: -9999px;
    width: 0;
    height: 0;
}




/* Border Radius */

.border-radius {
    border-radius: 4px !important;
}


/* Offset Anchor */

.offset-anchor {
    display: block;
    position: relative;
    visibility: hidden;
    top: -100px;
}


/* Responsive */

@media (max-width: 991px) {
    .offset-anchor {
        top: 0;
    }
}

/* Thumb Info */

.thumb-info {
    display: block;
    position: relative;
    text-decoration: none;
    max-width: 100%;
    background-color: #FFF;
    border-radius: 4px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.thumb-info .thumb-info-wrapper {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
    margin: 0;
    overflow: hidden;
    display: block;
    position: relative;
}

.thumb-info .thumb-info-wrapper.thumb-info-wrapper-demos {
    max-height: 330px;
}

.thumb-info .thumb-info-wrapper:after {
    content: "";
    background: rgba(33, 37, 41, 0.8);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
    bottom: -4px;
    color: #FFF;
    left: -4px;
    position: absolute;
    right: -4px;
    top: -4px;
    display: block;
    opacity: 0;
    z-index: 1;
}

.thumb-info .thumb-info-wrapper-opacity-0:after {
    opacity: 0.0;
}

.thumb-info .thumb-info-wrapper-opacity-1:after {
    opacity: 0.1;
}

.thumb-info .thumb-info-wrapper-opacity-2:after {
    opacity: 0.2;
}

.thumb-info .thumb-info-wrapper-opacity-3:after {
    opacity: 0.3;
}

.thumb-info .thumb-info-wrapper-opacity-4:after {
    opacity: 0.4;
}

.thumb-info .thumb-info-wrapper-opacity-5:after {
    opacity: 0.5;
}

.thumb-info .thumb-info-wrapper-opacity-6:after {
    opacity: 0.6;
}

.thumb-info .thumb-info-wrapper-opacity-7:after {
    opacity: 0.7;
}

.thumb-info .thumb-info-wrapper-opacity-8:after {
    opacity: 0.8;
}

.thumb-info .thumb-info-wrapper-opacity-9:after {
    opacity: 0.9;
}

.thumb-info .thumb-info-action-icon {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #CCC;
    border-radius: 0 0 0 4px;
    display: inline-block;
    font-size: 25px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    right: -100px;
    text-align: center;
    top: -100px;
    width: 50px;
    z-index: 2;
}

.thumb-info .thumb-info-action-icon i {
    font-size: 14px;
    right: 50%;
    position: absolute;
    top: 50%;
    color: #FFF;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    margin: -2px -1px 0 0px;
}

.thumb-info .thumb-info-inner {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: block;
}

.thumb-info .thumb-info-inner em {
    display: block;
    font-size: 0.8em;
    font-style: normal;
    font-weight: normal;
}

.thumb-info .thumb-info-title {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(33, 37, 41, 0.8);
    color: #FFF;
    font-weight: 600;
    left: 0;
    letter-spacing: -.05em;
    position: absolute;
    z-index: 2;
    max-width: 90%;
    font-size: 17px;
    padding: 13px 21px 2px;
    bottom: 13%;
}

.thumb-info .thumb-info-type {
    background-color: #CCC;
    border-radius: 2px;
    display: inline-block;
    float: left;
    font-size: 0.6em;
    font-weight: 600;
    letter-spacing: 0;
    margin: 8px -2px -15px 0px;
    padding: 2px 12px;
    text-transform: uppercase;
    z-index: 2;
    line-height: 2.3;
}

.thumb-info .owl-carousel {
    z-index: auto;
}

.thumb-info .owl-carousel .owl-dots {
    z-index: 2;
}

.thumb-info img {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 3px;
    position: relative;
    width: 100%;
}

.thumb-info:hover .thumb-info-wrapper:after {
    opacity: 1;
}

.thumb-info:hover .thumb-info-action-icon {
    right: 0;
    top: 0;
}

.thumb-info:hover .thumb-info-title {
    background: rgba(33, 37, 41, 0.9);
}

.thumb-info:hover img {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

.thumb-info.thumb-info-no-zoom img {
    -webkit-transition: none;
    transition: none;
}

.thumb-info.thumb-info-no-zoom:hover img {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
    background: rgba(23, 23, 23, 0.25);
}

.thumb-info.thumb-info-hide-wrapper-bg .thumb-info-wrapper:after {
    display: none;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 45px;
    text-align: center;
    opacity: 0;
    -webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
    transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
    -webkit-transform: translate3d(0, -70%, 0);
    transform: translate3d(0, -70%, 0);
    z-index: 2;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action a {
    text-decoration: none;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    width: 35px;
    height: 35px;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 5px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    z-index: 2;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon i {
    margin: 0;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon:hover {
    -webkit-transform: scale(1.15, 1.15);
    transform: scale(1.15, 1.15);
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action {
    opacity: 1;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action-icon {
    position: relative;
}

.thumb-info.thumb-info-centered-info .thumb-info-title {
    background: transparent;
    left: 5%;
    width: 90%;
    -webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
    transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    top: 50%;
    bottom: auto;
    opacity: 0;
    text-align: center;
    padding: 0;
    position: absolute;
}

.thumb-info.thumb-info-centered-info .thumb-info-type {
    float: none;
}

.thumb-info.thumb-info-centered-info:hover .thumb-info-title {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.thumb-info.thumb-info-bottom-info .thumb-info-title {
    background: #FFF;
    left: 0;
    width: 100%;
    max-width: 100%;
    -webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
    transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    top: auto;
    bottom: 0;
    opacity: 0;
    padding: 15px;
    text-shadow: none;
    color: #777;
}

.thumb-info.thumb-info-bottom-info .thumb-info-type {
    float: none;
    background: none;
    padding: 0;
    margin: 0;
}

.thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.thumb-info.thumb-info-bottom-info:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.thumb-info.thumb-info-bottom-info-linear .thumb-info-title {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgba(255, 255, 255, 0.8)), to(transparent)) repeat scroll 0 0 rgba(255, 255, 255, 0);
    background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(255, 255, 255, 0);
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
    background: #212529;
    color: #FFF;
}

.thumb-info.thumb-info-bottom-info-dark-linear .thumb-info-title {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgba(33, 37, 41, 0.8)), to(transparent)) repeat scroll 0 0 rgba(0, 0, 0, 0);
    background: linear-gradient(to top, rgba(33, 37, 41, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.thumb-info.thumb-info-bottom-info-show-more img {
    -webkit-transform: none !important;
    transform: none !important;
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-title {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-show-more-content {
    -webkit-transition: ease max-height 800ms;
    transition: ease max-height 800ms;
    overflow: hidden;
    max-height: 0;
    display: block;
}

.thumb-info.thumb-info-bottom-info-show-more:hover .thumb-info-show-more-content {
    max-height: 300px;
}

.thumb-info.thumb-info-push-hor img {
    -webkit-transform: translatex(-18px);
    transform: translatex(-18px);
    width: calc(100% + 19px);
    max-width: none;
}

.thumb-info.thumb-info-push-hor:hover img {
    -webkit-transform: translatex(0);
    transform: translatex(0);
}

.thumb-info.thumb-info-block .thumb-info-action-icon {
    top: 15px;
    right: 15px;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.thumb-info.thumb-info-block .thumb-info-wrapper:before {
    content: "";
    background: rgba(255, 255, 255, 0.9);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 0;
    bottom: 16px;
    bottom: 1rem;
    color: #FFF;
    left: 16px;
    left: 1rem;
    position: absolute;
    right: 16px;
    right: 1rem;
    top: 16px;
    top: 1rem;
    display: block;
    opacity: 0;
    z-index: 2;
}

.thumb-info.thumb-info-block:hover .thumb-info-wrapper:before {
    opacity: 1;
}

.thumb-info.thumb-info-block:hover .thumb-info-action-icon {
    opacity: 1;
}

.thumb-info.thumb-info-block.thumb-info-block-dark .thumb-info-wrapper:before {
    background: rgba(33, 37, 41, 0.9);
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-wrapper:after {
    opacity: 0.65;
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-title {
    opacity: 1;
    top: 50%;
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-type {
    float: none;
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-wrapper:after {
    opacity: 0.1;
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-title {
    opacity: 0;
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper:after {
    opacity: 0.65;
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper-no-opacity:after {
    opacity: 1;
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-title {
    opacity: 1;
    top: 0;
    height: 100%;
    top: 0;
    -webkit-transform: none !important;
    transform: none !important;
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-type {
    float: none;
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-1,
.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
    -webkit-transition: cubic-bezier(0.55, 0, 0.1, 1) top 500ms;
    transition: cubic-bezier(0.55, 0, 0.1, 1) top 500ms;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
    top: 150%;
}

.thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-1 {
    top: -50%;
}

.thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-2 {
    top: 50%;
}

.thumb-info.thumb-info-no-borders,
.thumb-info.thumb-info-no-borders img {
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper {
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
    border-radius: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded img,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper:after {
    border-radius: 4px;
}

.thumb-info.thumb-info-preview .thumb-info-wrapper:after {
    display: none;
}

.thumb-info.thumb-info-preview .thumb-info-image {
    min-height: 232px;
    display: block;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
    -webkit-transition: background-position 0.8s linear 0s;
    transition: background-position 0.8s linear 0s;
}

.thumb-info.thumb-info-preview:hover .thumb-info-image {
    -webkit-transition: background-position 2.5s linear 0s;
    transition: background-position 2.5s linear 0s;
    background-position: center bottom;
}

.thumb-info.thumb-info-preview.thumb-info-preview-short:hover .thumb-info-image {
    -webkit-transition: background-position 1s linear 0s;
    transition: background-position 1s linear 0s;
}

.thumb-info.thumb-info-preview.thumb-info-preview-long:hover .thumb-info-image {
    -webkit-transition: background-position 5s linear 0s;
    transition: background-position 5s linear 0s;
}

.thumb-info.thumb-info-no-overlay .thumb-info-wrapper:after {
    content: none;
}

.thumb-info.thumb-info-no-bg {
    background: transparent;
}


/* Thumb Info Ribbon */

.thumb-info-ribbon {
    background: #999;
    position: absolute;
    margin: -16px 0 0 0;
    padding: 5px 13px 6px;
    right: 15px;
    z-index: 1;
}

.thumb-info-ribbon:before {
    border-right: 10px solid #646464;
    border-top: 16px solid transparent;
    content: "";
    display: block;
    height: 0;
    left: -10px;
    position: absolute;
    top: 0;
    width: 7px;
}

.thumb-info-ribbon span {
    color: #FFF;
    font-size: 1.1em;
    font-family: "Shadows Into Light", cursive;
}


/* Thumb Info - Full Width */

.full-width .thumb-info img {
    border-radius: 0 !important;
}


/* Thumb Info Caption	*/

.thumb-info-caption {
    padding: 10px 0;
}

.thumb-info-caption .thumb-info-caption-text,
.thumb-info-caption p {
    font-size: 0.9em;
    margin: 0;
    padding: 15px 0;
    display: block;
}


/* Thumb Info Side Image	*/

.thumb-info-side-image .thumb-info-side-image-wrapper {
    padding: 0;
    float: left;
    margin-right: 20px;
}

.thumb-info-side-image-right .thumb-info-side-image-wrapper {
    float: right;
    margin-left: 20px;
    margin-right: 0;
}


/* Thumb Info Social Icons */

.thumb-info-social-icons {
    margin: 0;
    padding: 0;
    display: block;
}

.thumb-info-social-icons a {
    background: #CCC;
    border-radius: 25px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
}

.thumb-info-social-icons a:hover {
    text-decoration: none;
}

.thumb-info-social-icons a span {
    display: none;
}

.thumb-info-social-icons a i {
    color: #FFF;
    font-size: 0.9em;
    font-weight: normal;
}


/* Thumb Info Floating Caption */

.thumb-info-floating-caption-title {
    background: rgba(33, 37, 41, 0.8);
    color: #FFF;
    font-weight: 600;
    letter-spacing: -.05em;
    font-size: 17px;
    padding: 13px 21px;
}

.thumb-info-floating-caption-title .thumb-info-floating-caption-type {
    position: absolute;
    background-color: #CCC;
    border-radius: 2px;
    display: block;
    font-size: 0.6em;
    font-weight: 600;
    letter-spacing: 0;
    margin: 8px -2px -15px 0px;
    padding: 2px 12px;
    text-transform: uppercase;
    line-height: 2.3;
}


/* Responsive */

@media (max-width: 991px) {
    .thumb-info .thumb-info-title {
        font-size: 14px;
    }
    .thumb-info .thumb-info-more {
        font-size: 11px;
    }
}


/* Small */

small,
.small {
    font-size: 70%;
}


/* Page 404 */

.http-error {
    padding: 50px 0;
}

.http-error h2 {
    font-size: 140px;
    font-weight: 700;
    line-height: 140px;
}

.http-error h4 {
    color: #777;
}

.http-error p {
    font-size: 1.4em;
    line-height: 36px;
}


/* Responsive */

@media (max-width: 575px) {
    .http-error {
        padding: 0;
    }
    .http-error h2 {
        font-size: 100px;
        letter-spacing: 0;
        line-height: 100px;
    }
}
