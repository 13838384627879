/* Foot unten Fixieren */

.main {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
}

footer {
    margin-top: auto;
}