.hero-titel {
    font-weight: 900;
    font-size: 40px;
    line-height: 1.35;
    color: white;
    font-family: "Ubuntu", Arial, sans-serif;
    letter-spacing: 0em !important;
}

//* HERO *//
.hero {
    width: 100%;
    min-height: 550px;
    background: $primary;
    background: linear-gradient(180deg, $primary 0%, $primary-light 100%);
    .container {
        padding: 0px 15px!important;
    }
    .row {
        min-height: 500px;
    }
    .col {
        &-md5,
        &-md-7 {
            padding: 30px 15px;
        }
    }
    h1 {
        color: #fff;
        margin-bottom: 15px;
        font-size: 50px;
    }
    p {
        color: #fff;
        margin-bottom: 40px;
    }
    .lead {
        line-height: 1.6;
        font-weight: 400;
        letter-spacing: 0;
    }
}