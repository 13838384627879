//** VARIABLES **//
$primary: #077c3f;
$primary-light:#1ba466;
$primary-extra-light: #a2f7d1;
$gray: #f4f4f4;
$border-gray: #e4e4e4;
$dark-gray:#505050;
$font-heading: 'Ubuntu',
Arial,
sans-serif;
$btn-shadow:0px 10px 40px 4px rgba(0, 0, 0, 0.2);
$btn-shadow-hover: 0px 10px 30px 1px rgba(0, 0, 0, 0.25);