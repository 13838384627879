#header {
    height: 167px;
    .header-body {
        position: fixed;
    }
}


/* Button oben rechts Mobil (3 Striche) */

#header .header-btn-collapse-nav {
    background-color: $primary !important;
}

.dropdown .dropdown-item {
    font-weight: bold !important;
}

@media (max-width: 991px) {
    #header {
        height: 105px !important;
        .header-nav-bar {
            height: 1px !important;
            background-color: #e3e3e3 !important;
        }
    }
}