.svg-inline--fa.fa-w-16 {
    width: 1em;
}

.svg-inline--fa,
svg:not(:root).svg-inline--fa {
    overflow: visible;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}