.liste-lps {
    display: flex;         /* Aktiviere Flexbox */
    flex-wrap: wrap;       /* Erlaube das Umbruch der Elemente */
    max-height: 65px;     /* Maximale Höhe der Liste */
    overflow-y: hidden;      /* Scrollbalken bei Bedarf */
    list-style-type: none; /* Entferne Aufzählungssymbole */
}

.liste-lps li {
    margin-right: 10px;    /* Füge etwas Abstand zwischen den Elementen hinzu */
}
